.form {
    @apply accent-primary;

    *:focus {
        outline: none !important;
        border: 1px solid theme("colors.primary");
    }

    .checkbox-container {
        input[type="checkbox"] {
            @apply scale-150;
        }

        label {
            color: theme("colors.gray.800") !important;
        }
    }

    textarea {
        min-height: 250px;
    }

    .phone-field {
        select,
        input {
            @apply border-0;
        }

        input {
            @apply pl-4;
        }
    }

    label.required {
        p {
            @apply inline;

            // Hide trailing br
            br:last-child {
                display: none !important;
            }
        }

        &::after {
            content: " *";
        }
    }

    label {
        a {
            color: theme("colors.primary") !important;
            @apply underline underline-offset-2;
        }
    }
}

.dark .form .checkbox-container label {
    color: theme("colors.white") !important;
}
