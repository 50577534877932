.button-filled{
    @apply bg-primary text-white px-5 pt-3.5 pb-5 xxl:px-6 uppercase font-industry font-black text-18 md:text-21 xxl:text-32 leading-100 block w-fit relative isolate origin-center overflow-hidden transition-transform ease-in-out duration-300;

    &::after{
        content: "";

        @apply bg-white absolute left-0 top-0 w-full h-full -z-10 transition-all ease-in-out duration-500 origin-left scale-x-0;

        transition-property: transform, opacity;
    }

    &:hover{
        @apply scale-95;

        &::after{
            @apply scale-x-100 opacity-0;
        }
    }

    &.button-inverted{
        @apply bg-white text-primary;

        &::after{
            @apply bg-primary;
        }
    }
}

.button-outlined{
    @apply border-4 border-primary text-primary px-4 pt-2.5 pb-4 xxl:px-5 uppercase font-industry font-black text-18 md:text-21 xxl:text-32 leading-100 block w-fit relative isolate origin-center overflow-hidden transition-transform ease-in-out duration-300;

    &::after{
        content: "";

        @apply bg-white absolute left-0 top-0 w-full h-full -z-10 transition-all ease-in-out duration-500 origin-left scale-x-0;

        transition-property: transform, opacity;
    }

    &:hover{
        @apply scale-95;

        &::after{
            @apply scale-x-100 opacity-0;
        }
    }
}